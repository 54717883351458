import { Pipe, PipeTransform } from '@angular/core';

// Preparaciones de pedidos
// Tabla: repartos-> campo: estado
@Pipe({
  name: 'repartoEstado'
})
export class RepartoEstadoPipe implements PipeTransform {

  transform(value: unknown, ...args: unknown[]): unknown {
    // 1 normal, 2 empezado, 3 finalizado, 5 Artículo cancelado en pedido, 8 incidencia

    switch (value) {
      case '1':
        return '<span class="text-info">Pendiente</span>';
      case '2':
        return '<span class="text-warning">Empezado</span>';
      case '3':
        return '<span class="text-success">Finalizado</span>';
      case '5':
        return '<span class="text-warning">Cancelado en pedido</span>';
      case '8':
        return '<span class="text-danger">Incidencia</span>';
      default:
        return '<span class="text-muted">Desconocido</span>';
    }

  }

}
