import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'pedidoEstado'
})
export class PedidoEstadoPipe implements PipeTransform {

  transform(value: unknown, ...args: unknown[]): unknown {
    // 1 Pendiente, 3 Servido, 4 Cancelado, 5 Servido parcialmente, 8 Incidencia
    switch (value) {
      case '1':
        return '<span class="text-info">Pendiente</span>';
        break;
      case '3':
        return '<span class="text-success">Servido</span>';
        break;
      case '4':
        return '<span class="text-danger">Cancelado</span>';
        break;
      case '5':
        return '<span class="text-success">Servido con faltas</span>';
        break;
      case '8':
        return '<span class="text-danger">Incidencia</span>';
        break;
    }
    return value;

  }

}
