<div class="row page-titles">
    <div class="col-md-5 align-self-center">
        <h3 class="text-themecolor">Embalaje para campaña</h3>
        <h3 class="text-themecolor">
            {{campanya.camp_cod}}</h3>
    </div>
</div>

<div class="row animated fadeIn fast">

    <!-- Campaña -->
    <div class="col-12">
        <div class="card border rounded animated fadeIn fast">
            <div class="card-body">

                <div class="row">
                    <div class="col-6">
                        <h6 class="card-title ml-1">
                            {{cliente.nombre}}
                        </h6>

                        <h6 class="card-subtitle ml-4">
                            {{campanya.fecha_entrada | date: 'dd/MM/yyyy HH:mm'}} <br>
                            {{campanya.descripcion}} <br>
                        </h6>
                    </div>

                    <div class="col-6">

                        <form class="form"
                              [ngClass]="{'form-horizontal': true}">

                            <!-- boxInput -->
                            <div class="form-group"
                                 [ngClass]="{ 'has-danger': boxInputError }">
                                <label class="form-control-label">Etiqueta Caja:</label>
                                <input type="text"
                                       name="boxInput"
                                       [(ngModel)]="boxInput"
                                       class="form-control"
                                       (keyup.enter)="validarBoxInput()" />

                                <div *ngIf="boxInputError"
                                     class="form-control-feedback">
                                    <div>Etiqueta errónea</div>
                                </div>
                            </div>

                        </form>
                    </div>
                </div>

            </div>

            <div class="card-footer">
                <div class="d-flex justify-content-between">

                    <a routerLink="/pda"
                       class="btn waves-effect waves-light btn-rounded btn-secondary">
                        <i *ngIf="loading"
                           class="fa fa-spinner fa-spin"></i>
                        Cancelar
                    </a>

                    <button [disabled]="loading"
                            (click)="todoEmbalado()"
                            type="button"
                            class="ml-4 btn waves-effect waves-light btn-rounded btn-success">
                        <i *ngIf="loading"
                           class="fa fa-spinner fa-spin"></i>
                        Finalizar embalajes</button>
                </div>
            </div>

        </div>
    </div>


    <!-- Lista de pedidos -->
    <div *ngIf="pedidosList.length"
         class="col-md-12 col-xs-12 animated fadeIn fast">

        <div class="card border border-info rounded">
            <div class="card-body">
                <h4 class="card-title text-info">Pedidos pendientes de informar embalaje</h4>
                <div class="bootstrap-table">


                    <div class="table-responsive">
                        <table class="table table-sm table-striped table-bordered table-hover">

                            <thead>
                                <tr>
                                    <th>Cajetín</th> 
                                    <th>Pedido</th> 
                                    <th>Destino</th>
                                </tr>
                            </thead>

                            <tbody>
                                <ng-container *ngFor="let lin of pedidosList; let i = index">
                                    <tr>
                                        <td>{{ lin.cajetin }}</td>
                                        <td> {{ lin.pedido_cli }} </td>
                                        <td> {{ lin.cp }} - {{ lin.poblacion }} </td>
                                        <td>
                                            <button (click)="abrirModal(lin)"
                                                    class="btn btn-primary">Embalaje</button>
                                        </td>
                                    </tr>
                                </ng-container>
                            </tbody>

                        </table>
                    </div>



                </div>
            </div>

            <div class="clearfix"></div>
        </div>

    </div>



</div>


<!-- modal para facturación de pedidos -->
<app-modal-fact-salida></app-modal-fact-salida>