<div class="row animated fadeIn fast">

    <div class="col-md-10 ">
        <div class="card">
            <div class="card-body">
                <h4 class="card-title">Perfil de usuario</h4>
                <h6 class="card-subtitle">Modificar los datos de usuario</h6>

                <form class="form p-t-20"
                      [formGroup]="usuarioForm"
                      (ngSubmit)="enviar()">

                    <div class="row">

                        <div class="col-md-8">
                            <div class="row">

                                <!-- username -->
                                <div class="col-md-4">
                                    <div class="form-group"
                                         [ngClass]="{ 'has-danger': enviado && f['username'].errors }">
                                        <label class="form-control-label">Usuario</label>
                                        <div class="input-group">
                                            <div class="input-group-addon"><i class="fa-solid fa-user"></i></div>
                                            <input [readonly]="paramId !== 'new'"
                                                   type="text"
                                                   formControlName="username"
                                                   class="form-control"
                                                   trim="blur"
                                                   [ngClass]="{ 'form-control-danger': enviado && f['username'].errors }">
                                        </div>
                                        <div *ngIf="enviado && f['username'].errors"
                                             class="form-control-feedback">
                                            <div *ngIf="f['username'].errors['required']">Obligatorio</div>
                                            <div *ngIf="f['username'].errors['minlength']">Debe contener un mínimo de 4 caracteres</div>
                                            <div *ngIf="f['username'].errors['maxlength']">Nombre no puede exceder de 150 caracteres</div>
                                        </div>
                                    </div>
                                </div>

                                <!-- rol -->
                                <div class="col-md-4">
                                    <div class="form-group"
                                         [ngClass]="{ 'has-danger': enviado && f['rol'].errors }">
                                        <label class="form-control-label">Rol</label>
                                        <div class="input-group">
                                            <div class="input-group-addon"><i class="fa-solid fa-list-check"></i></div>

                                            <select [disabled]="paramId !== 'new'"
                                                    formControlName="rol"
                                                    (ngModelChange)="onRolChange()"
                                                    class="form-control"
                                                    [ngClass]="{ 'form-control-danger': enviado && f['rol'].errors }">
                                                <option>admin</option>
                                                <option>usuario</option>
                                                <option>cliente</option>
                                                <option>sa</option>
                                            </select>
                                        </div>
                                        <div *ngIf="enviado && f['rol'].errors"
                                             class="form-control-feedback">
                                            <div *ngIf="f['rol'].errors['required']">Obligatorio</div>
                                            <div *ngIf="usuarioForm.get('rol')?.errors?.['apiError']">{{
                                                usuarioForm.get('rol')?.errors?.['apiError'] }}</div>
                                        </div>
                                    </div>
                                </div>


                                <!-- alm_por_defecto -->
                                <div *ngIf="usuarioSrv.userdata.rol  === 'admin'"
                                     class="col-md-4">
                                    <div class="form-group"
                                         [ngClass]="{ 'has-danger': enviado && f['alm_por_defecto'].errors }">
                                        <label class="form-control-label">Almacen predeterminado</label>
                                        <div class="input-group">
                                            <div class="input-group-addon"> <i class="fa-solid fa-warehouse"></i></div>

                                            <select formControlName="alm_por_defecto"
                                                    class="form-control"
                                                    [ngClass]="{ 'form-control-danger': enviado && f['alm_por_defecto'].errors }">
                                                <option value="">Todos los almacenes</option>
                                                <option *ngFor="let alm of almacenesList"
                                                        [value]="alm.id">
                                                    {{alm.nombre}}
                                                </option>
                                            </select>
                                        </div>
                                        <div *ngIf="enviado && f['alm_por_defecto'].errors"
                                             class="form-control-feedback">
                                            <div *ngIf="usuarioForm.get('alm_por_defecto')?.errors?.['apiError']">{{
                                                usuarioForm.get('alm_por_defecto')?.errors?.['apiError'] }}</div>
                                        </div>
                                    </div>
                                </div>

                                <!-- nombre -->
                                <div class="col-md-4">
                                    <div class="form-group"
                                         [ngClass]="{ 'has-danger': enviado && f['nombre'].errors }">
                                        <label class="form-control-label">Nombre</label>
                                        <input type="text"
                                               formControlName="nombre"
                                               class="form-control"
                                               [ngClass]="{ 'form-control-danger': enviado && f['nombre'].errors }">
                                        <div *ngIf="enviado && f['nombre'].errors"
                                             class="form-control-feedback">
                                            <div *ngIf="f['nombre'].errors['required']">Obligatorio</div>
                                            <div *ngIf="f['nombre'].errors['minlength']">Debe contener un mínimo de 4 caracteres</div>
                                            <div *ngIf="f['nombre'].errors['maxlength']">Nombre no puede exceder de 150 caracteres</div>
                                            <div *ngIf="usuarioForm.get('nombre')?.errors?.['apiError']">{{usuarioForm.get('nombre')?.errors?.['apiError'] }}</div>
                                        </div>
                                    </div>
                                </div>

                                <!-- apellidos -->
                                <div class="col-md-8">
                                    <div class="form-group"
                                         [ngClass]="{ 'has-danger': enviado && f['apellidos'].errors }">
                                        <label class="form-control-label">Apellidos</label>
                                        <input type="text"
                                               formControlName="apellidos"
                                               class="form-control"
                                               [ngClass]="{ 'form-control-danger': enviado && f['apellidos'].errors }">
                                        <div *ngIf="enviado && f['apellidos'].errors"
                                             class="form-control-feedback">
                                            <div *ngIf="f['apellidos'].errors['required']">Obligatorio</div>
                                            <div *ngIf="f['apellidos'].errors['minlength']">Debe contener un mínimo de 4 caracteres</div>
                                            <div *ngIf="f['apellidos'].errors['maxlength']">No puede exceder de 150 caracteres</div>
                                            <div *ngIf="usuarioForm.get('apellidos')?.errors?.['apiError']">{{usuarioForm.get('apellidos')?.errors?.['apiError'] }}</div>
                                        </div>
                                    </div>
                                </div>


                            </div>

                        </div>

                        <!-- foto -->
                        <div class="col-md-3">
                            <label class="form-control-label ml-4 mr-1">Modificar foto </label>

                            <img [src]="fotoDir +'/users/'+ usuario.foto"
                                 loading="lazy"
                                 (click)="abrirModal(usuario)"
                                 title="Cambiar ó borrar foto"
                                 alt="Foto"
                                 class="img-thumbnail img-avatar pointer">
                        </div>


                    </div>

                    <div class="row">


                        <!-- email -->
                        <div class="col-md-8">
                            <div class="form-group"
                                 [ngClass]="{ 'has-danger': enviado && f['email'].errors }">
                                <label class="form-control-label">Email</label>
                                <input type="text"
                                       formControlName="email"
                                       class="form-control"
                                       [ngClass]="{ 'form-control-danger': enviado && f['email'].errors }">
                                <div *ngIf="enviado && f['email'].errors"
                                     class="form-control-feedback">
                                    <div *ngIf="f['email'].errors['required']">Obligatorio</div>
                                    <div *ngIf="f['email'].errors['eMailFormat']">No parece un eMail válido</div>
                                    <div *ngIf="usuarioForm.get('email')?.errors?.['apiError']">{{usuarioForm.get('email')?.errors?.['apiError'] }}</div>
                                </div>
                            </div>
                        </div>


                        <!-- telefono -->
                        <div class="col-md-4">
                            <div class="form-group"
                                 [ngClass]="{ 'has-danger': enviado && f['telefono'].errors }">
                                <label class="form-control-label">Teléfono</label>
                                <input type="text"
                                       formControlName="telefono"
                                       class="form-control"
                                       [ngClass]="{ 'form-control-danger': enviado && f['telefono'].errors }">
                                <div *ngIf="enviado && f['telefono'].errors"
                                     class="form-control-feedback">
                                    <div *ngIf="f['telefono'].errors['required']">Obligatorio</div>
                                    <div *ngIf="usuarioForm.get('telefono')?.errors?.['apiError']">{{usuarioForm.get('telefono')?.errors?.['apiError'] }}</div>
                                </div>
                            </div>
                        </div>


                    </div> <!-- row -->



                    <!-- INTRANET CLIENTE -->


                    <div *ngIf="usuarioForm.get('rol')?.value === 'cliente'"
                         class="card border border-secondary rounded intranetCard">
                        <div class="card-body ">
                            <h4 class="card-title"><i class="fa-solid fa-user-tie"></i> Intranet de Cliente</h4>

                            <div class="row">

                                <!-- id_cliente -->
                                <div class="col-md-6">
                                    <div class="form-group"
                                         [ngClass]="{ 'has-danger': enviado && f['id_cliente'].errors }">
                                        <label class="form-control-label">Pertenece al cliente:</label>
                                        <div class="input-group">
                                            <div class="input-group-addon"> <i class="fa-solid fa-users"></i></div>

                                            <select formControlName="id_cliente"
                                                    class="form-control"
                                                    [ngClass]="{ 'form-control-danger': enviado && f['id_cliente'].errors }">
                                                <option value="">Ninguno</option>
                                                <option *ngFor="let cliente of clientesList"
                                                        [value]="cliente.id">
                                                    {{cliente.nombre}}
                                                </option>
                                            </select>
                                        </div>
                                        <div *ngIf="enviado && f['id_cliente'].errors"
                                             class="form-control-feedback">
                                            <div *ngIf="f['id_cliente'].errors['required']">Obligatorio</div>
                                            <div *ngIf="usuarioForm.get('id_cliente')?.errors?.['apiError']">{{
                                                usuarioForm.get('id_cliente')?.errors?.['apiError'] }}</div>
                                        </div>
                                    </div>
                                </div>




                                <!-- rolIntranet -->
                                <div class="col-md-6">
                                    <div class="form-group"
                                         [ngClass]="{ 'has-danger': enviado && f['rolIntranet'].errors }">
                                        <label class="form-control-label">Rol en la Intranet</label>
                                        <div class="input-group">
                                            <div class="input-group-addon"><i class="fa-solid fa-list-check"></i></div>

                                            <select formControlName="rolIntranet"
                                                    class="form-control"
                                                    [ngClass]="{ 'form-control-danger': enviado && f['rolIntranet'].errors }">
                                                <option>admin</option>
                                                <option>consultas</option>
                                            </select>
                                        </div>
                                        <div *ngIf="enviado && f['rolIntranet'].errors"
                                             class="form-control-feedback">
                                            <div *ngIf="f['rolIntranet'].errors['required']">Obligatorio</div>
                                            <div *ngIf="usuarioForm.get('rolIntranet')?.errors?.['apiError']">{{
                                                usuarioForm.get('rolIntranet')?.errors?.['apiError'] }}</div>
                                        </div>
                                    </div>
                                </div>


                            </div> <!-- row INTRANET cliente-->

                        </div> <!-- card-body -->
                    </div> <!-- card -->




                    <div class="row"
                         *ngIf="usuarioForm.get('rol')?.value !== 'cliente'">

                        <!-- dir_export_DTX -->
                        <div class="col-md-4">
                            <div class="form-group"
                                 [ngClass]="{ 'has-danger': enviado && f['dir_export_DTX'].errors }">
                                <label class="form-control-label">Carpeta Exportación de envíos (xls DTX)</label>
                                <input type="text"
                                       formControlName="dir_export_DTX"
                                       class="form-control"
                                       [ngClass]="{ 'form-control-danger': enviado && f['dir_export_DTX'].errors }">
                                <div *ngIf="enviado && f['dir_export_DTX'].errors"
                                     class="form-control-feedback">
                                    <div *ngIf="f['dir_export_DTX'].errors['required']">Obligatorio</div>
                                    <div *ngIf="usuarioForm.get('dir_export_DTX')?.errors?.['apiError']">{{usuarioForm.get('dir_export_DTX')?.errors?.['apiError'] }}</div>
                                </div>
                            </div>
                        </div>



                        <!-- id_prnLaser -->
                        <div class="col-md-4">
                            <div class="form-group"
                                 [ngClass]="{ 'has-danger': enviado && f['id_prnLaser'].errors }">
                                <label class="form-control-label">Impresora Laser</label>
                                <div class="input-group">
                                    <div class="input-group-addon"> <i class="fa-solid fa-print"></i></div>

                                    <select formControlName="id_prnLaser"
                                            class="form-control"
                                            [ngClass]="{ 'form-control-danger': enviado && f['id_prnLaser'].errors }">
                                        <option value="">Selecciona...</option>
                                        <option *ngFor="let prn of prnLaserList"
                                                [value]="prn.id">
                                            {{prn.nombre}}
                                        </option>
                                    </select>
                                </div>
                                <div *ngIf="enviado && f['id_prnLaser'].errors"
                                     class="form-control-feedback">
                                    <div *ngIf="f['id_prnLaser'].errors['required']">Obligatorio</div>
                                    <div *ngIf="usuarioForm.get('id_prnLaser')?.errors?.['apiError']">{{
                                        usuarioForm.get('id_prnLaser')?.errors?.['apiError'] }}</div>
                                </div>
                            </div>
                        </div>



                        <!-- id_prnEtiquetas -->
                        <div class="col-md-4">
                            <div class="form-group"
                                 [ngClass]="{ 'has-danger': enviado && f['id_prnEtiquetas'].errors }">
                                <label class="form-control-label">Etiquetadora</label>
                                <div class="input-group">
                                    <div class="input-group-addon"> <i class="fa-regular fa-note-sticky"></i></div>

                                    <select formControlName="id_prnEtiquetas"
                                            class="form-control"
                                            [ngClass]="{ 'form-control-danger': enviado && f['id_prnEtiquetas'].errors }">
                                        <option value="">Selecciona...</option>
                                        <option *ngFor="let prn of prnEtiqList"
                                                [value]="prn.id">
                                            {{prn.nombre}}
                                        </option>
                                    </select>
                                </div>
                                <div *ngIf="enviado && f['id_prnEtiquetas'].errors"
                                     class="form-control-feedback">
                                    <div *ngIf="f['id_prnEtiquetas'].errors['required']">Obligatorio</div>
                                    <div *ngIf="usuarioForm.get('id_prnEtiquetas')?.errors?.['apiError']">{{
                                        usuarioForm.get('id_prnEtiquetas')?.errors?.['apiError'] }}</div>
                                </div>
                            </div>
                        </div>


                    </div> <!-- row -->



                    <div class="card border border-warning rounded">
                        <div class="card-body passwordCard">
                            <h4 class="card-title"><i class="fa-solid fa-lock"></i> Cambiar contraseña</h4>
                            <div class="row">

                                <!-- password -->
                                <div class="col-md-6 ">
                                    <div class="form-group"
                                         [ngClass]="{ 'has-danger': enviado && f['password'].errors }">
                                        <label class="form-control-label">Password</label>
                                        <input type="password"
                                               formControlName="password"
                                               class="form-control"
                                               [ngClass]="{ 'form-control-danger': enviado && f['password'].errors }"
                                               placeholder="Nueva contraseña">
                                        <div *ngIf="enviado && f['password'].errors"
                                             class="form-control-feedback">
                                            <div *ngIf="f['password'].errors['required']">Obligatorio</div>
                                            <div *ngIf="usuarioForm.get('password')?.errors?.['apiError']">{{usuarioForm.get('password')?.errors?.['apiError'] }}</div>
                                        </div>
                                    </div>
                                </div>


                                <!-- password2 -->
                                <div class="col-md-6">
                                    <div class="form-group"
                                         [ngClass]="{ 'has-danger': enviado && f['password2'].errors }">
                                        <label class="form-control-label">Repite password</label>
                                        <input type="password"
                                               formControlName="password2"
                                               class="form-control"
                                               [ngClass]="{ 'form-control-danger': enviado && f['password2'].errors }">
                                        <div *ngIf="enviado && f['password2'].errors"
                                             class="form-control-feedback">
                                            <div *ngIf="f['password2'].errors['required']">Password obligatorio</div>
                                            <div *ngIf="f['password2'].errors['matching']">Los passwords no coinciden</div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                    <!-- <div class="d-flex">
                        <h6 class="card-subtitle">
                            <li *ngIf="usuarioSrv.userdata.created_at"> Creado hace {{usuarioSrv.userdata.created_at! | friendlyDdmmyy}}</li>
                            <li *ngIf="usuarioSrv.userdata.updated_at"> Última modificación hace {{usuarioSrv.userdata.updated_at! | friendlyDdmmyy}}</li>
                        </h6>
                        <button [disabled]="loading"
                                type="submit"
                                class="ml-auto btn waves-effect waves-light btn-rounded btn-success"> <i class="fa fa-spinner fa-spin"
                               *ngIf="loading"></i> Enviar</button>
                    </div> -->

                    <div class="d-flex">
                        <button *ngIf="volver_a"
                                [disabled]="loading"
                                type="button"
                                class="btn waves-effect waves-light btn-rounded btn-secondary"
                                [routerLink]="volver_a"
                                data-dismiss="modal">Cancelar</button>
                        <h6 class="card-subtitle ml-4 ">
                            <li *ngIf="usuarioSrv.userdata.created_at"> Creado hace {{usuarioSrv.userdata.created_at! | friendlyDdmmyy}}</li>
                            <li *ngIf="usuarioSrv.userdata.updated_at"> Última modificación hace {{usuarioSrv.userdata.updated_at! | friendlyDdmmyy}}</li>
                        </h6>
                        <button [disabled]="loading"
                                type="submit"
                                class="ml-auto btn waves-effect waves-light btn-rounded btn-success">
                            <i *ngIf="loading"
                               class="fa fa-spinner fa-spin"></i>
                            Enviar</button>
                    </div>

                </form>
            </div>
        </div>
    </div>

</div>