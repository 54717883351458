<!-- ============================================================== -->
<!-- Main wrapper - style you can find in pages.scss -->
<!-- ============================================================== -->
<section id="wrapper">
    <div class="login-register animated fadeIn fast"
         style="background-color: lightgray;">
        <div class="login-box card shadow p-3 mb-5 bg-white rounded">
            <div class="card-body">

                <form [formGroup]="loginForm"
                      (ngSubmit)="enviar()"
                      autocomplete="off"
                      class="form-horizontal form-material"
                      id="loginform">
                    <p class="text-center db">
                        <img class="ml-4"
                             src="./assets/images/logo-icon.png"
                             alt="viapack Logo" />
                        <img src="./assets/images/logo-text.png"
                             alt="InnovaCen Logo" />
                    </p>

                    <!-- username -->
                    <div class="form-group m-t-40">
                        <div class="col-xs-12">
                            <div class="input-group">
                                <div class="input-group-addon"><i class="fa-solid fa-user"></i></div>
                                <input formControlName="username"
                                       class="form-control my-input"
                                       type="text"
                                       placeholder="Usuario">
                            </div>
                        </div>
                    </div>


                    <!-- password -->
                    <div class="form-group">
                        <div class="col-xs-12">
                            <div class="input-group">
                                <div class="input-group-addon"><i class="fa-solid fa-key"></i></div>
                                <input formControlName="password"
                                       class="form-control my-input"
                                       type="password"
                                       required=""
                                       placeholder="Contraseña">
                            </div>
                        </div>
                    </div>

                    <!-- remember -->
                    <div class="form-group row">
                        <div class="col-4 text-left">
                            <div class="checkbox checkbox-primary pull-left p-t-0">
                                <input id="checkbox-signup"
                                       type="checkbox"
                                       class="filled-in chk-col-light-blue"
                                       formControlName="remember">
                                <label for="checkbox-signup"> Recordar </label>
                            </div>
                        </div>
                        <div class="col-8 text-right">
                            <a class="d-flex align-items-center link-unstyled"
                               href="#"
                               (click)="recordarPsw()"
                               class="text-dark pull-right">
                               <i class="fa fa-lock m-r-5"></i> ¿Contraseña olvidada?
                            </a>
                        </div>
                    </div>

                    <div class="form-group text-center m-t-20">
                        <div class="col-xs-12">
                            <button class="btn btn-info btn-lg btn-block text-uppercase btn-rounded"
                                    type="submit">
                                <i *ngIf="loading"
                                   class="fa fa-spinner fa-spin"></i>
                                Entrar
                            </button>
                        </div>
                    </div>

                </form>

            </div>
        </div>
    </div>
</section>

<!-- ============================================================== -->
<!-- End Wrapper -->
<!-- ============================================================== -->