import { Component, OnInit } from '@angular/core';
import { UsuarioService } from 'src/app/services/datos/usuario.service'; 

@Component({
  selector: 'app-cabecera',
  templateUrl: './cabecera.component.html',
  styleUrls: ['./cabecera.component.scss']
})
export class CabeceraComponent implements OnInit {

  pantallacompleta: boolean = true;

  constructor(
    public usuarioSrv: UsuarioService, 
  ) { }
  ngOnInit(): void {
    this.goFullScreen();
  }

  logOut() {
    this.usuarioSrv.logOut();
  }

  getUserFoto() {
    return (this.usuarioSrv.getUserFoto());
  }

  goFullScreen() {
    const elem = document.documentElement;
    if (elem.requestFullscreen) {
      elem.requestFullscreen();
      this.pantallacompleta = true;
    }
  }

  exitFullScreen() {
    if (document.exitFullscreen) {
      document.exitFullscreen();
      this.pantallacompleta = false;
    }
  }

}
