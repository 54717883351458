import { ClientesService } from './../../services/datos/clientes.service';
import * as Const from '../../shared/constants';

// Componentes 
import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

// Servicios
import { UsuarioService } from 'src/app/services/datos/usuario.service';
import { NotificacionesService } from 'src/app/services/notificaciones.service';
import { ModalImagenService } from 'src/app/services/modal-imagen.service';
import { AlmacenesService } from 'src/app/services/datos/almacenes.service';
import { ImpresorasService } from './../../services/datos/impresoras.service';

// Customs y terceros
import CustomVal from '../../providers/CustomValidators';

// interfaces
import { IAlmacen } from 'src/app/interfaces/almacenes';
import { IImpresora } from 'src/app/interfaces/IImpresora';
import { ActivatedRoute, Router } from '@angular/router';
import { IUsuario } from 'src/app/models/usuario';
import { Subscription, delay } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ICliente } from 'src/app/interfaces/cliente';

@Component({
  selector: 'app-usr-perfil',
  templateUrl: './usr-perfil.component.html',
  styleUrls: ['./usr-perfil.component.scss']
})
export class UsrPerfilComponent implements OnInit {

  // Usar constantes en el template
  Const = Const

  usuario: IUsuario = {}
  paramId: string = '';

  public fotoDir: string = environment.fotoDir;
  public imgSubs: Subscription | undefined;

  public imagenFile: File | undefined;
  public usuarioForm: FormGroup = new FormGroup({});

  public enviado: boolean = false;
  public loading: boolean = false;

  private userId: string = 'new'
  public volver_a: string | null = null;

  almacenesList: IAlmacen[] = [];
  clientesList: ICliente[] = [];
  prnLaserList: IImpresora[] = [];
  prnEtiqList: IImpresora[] = [];


  constructor(
    private fb: FormBuilder,
    public usuarioSrv: UsuarioService,
    public almacenesSrv: AlmacenesService,
    public impresorasSrv: ImpresorasService,
    public clientesService: ClientesService,
    private notificacionesSrv: NotificacionesService,
    public modalImagenSrv: ModalImagenService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
  ) { }


  ngOnInit(): void {

    this.usuarioForm = this.fb.group({
      username: ['', [Validators.required, Validators.minLength(4), Validators.maxLength(20)]],
      rol: [{ value: '', disabled: true }, Validators.required],
      rolIntranet: [''],
      alm_por_defecto: [''],
      nombre: ['', [Validators.required, Validators.minLength(4), Validators.maxLength(150)]],
      apellidos: ['', [Validators.required, Validators.minLength(4), Validators.maxLength(150)]],
      email: ['', [Validators.required, CustomVal.ValidateEmail]],
      telefono: [''],
      id_cliente: [''],
      id_prnLaser: ['', Validators.required],
      id_prnEtiquetas: ['', Validators.required],
      dir_export_DTX: ['', Validators.required],
      password: [''],
      password2: ['']
    },
      {
        validators: [CustomVal.match('password', 'password2')]
      });


    // Mirar si se pasan valores por default
    const defaultData = history.state;
    if (defaultData) {
      this.usuarioForm.patchValue({
        rol: defaultData.rol || '',
        rolIntranet: defaultData.rolIntranet || '',
        id_cliente: defaultData.id_cliente || '',
        alm_por_defecto: defaultData.alm_por_defecto || ''
      });
      this.volver_a = defaultData.volver_a;
      this.onRolChange();

      console.log('__defaultData:', defaultData);
    }



    // Suscribirse a los parámetros de la url, si envían una id, cargar los datos del usuario
    this.activatedRoute.params.subscribe(({ id }) => {
      this.paramId = id;

      if (this.paramId != 'new') {

        // Existe paramId, Es un usuario nuevo ó existente 
        if (/^\d/.test(this.paramId)) {
          // El valor de this.paramId comienza con un número, es un USUARIO EXISTENTE 

          this.userId = id;
          this.cargarUsuario(id);
        } else {

          // Es la edición del USUARIO conectado
          this.usuario = this.usuarioSrv.userdata

          this.userId = this.usuarioSrv.userdata.id!.toString();
          this.cargarUsuario(this.usuarioSrv.userdata.id!);

          console.log('__edición del USUARIO conectado', this.usuario)
        }
      }
    })


    // Nos suscribimos a la imagen, 
    // Si cambia la imagen, actualizar usuario.foto

    this.imgSubs = this.modalImagenSrv.nuevaImagen
      .pipe(delay(100))
      .subscribe(img => {
        this.usuario.foto = img.guardado_como;
      });



    // Valores por defecto para registros nuevos
    if (this.paramId === 'new') {
      this.userId = 'new';

      this.usuario.password = '';
      this.usuario.foto = '_noFoto.png';

      this.cambiarEstadoRol(false);
      this.usuarioForm.patchValue({
        swActivo: '1',
        foto: '_noFoto.png',
      });
    }

    this.onRolChange();
    this.getAlmacenes();
    this.getClientes();
    this.getImpresoras();
    this.getEtiquetadoras();

  }


  // Métodos  //

  // Carga en this.usuario un usuario desde la tabla usuaris
  cargarUsuario(id: number) {
    this.loading = true;
    this.usuarioSrv.getUserDetall(id)
      .subscribe(resp => {
        this.loading = false;
        if (resp.error) {
          this.notificacionesSrv.aviso('error', resp.mensaje);
          this.usuarioSrv.logOut();
        } else {
          this.usuario = resp.data;

          console.log('__cargarUsuario', this.usuario);

          this.loadFormUser(this.usuario)

        }
      })

  }

  // Carga en el formulario los valores de un usuario
  loadFormUser(usr: IUsuario) {
    this.usuarioForm.patchValue({
      username: usr.username,
      rol: usr.rol,
      rolIntranet: usr.rolIntranet,
      alm_por_defecto: usr.alm_por_defecto,
      nombre: usr.nombre,
      apellidos: usr.apellidos,
      email: usr.email,
      telefono: usr.telefono,
      id_cliente: usr.id_cliente,
      id_prnLaser: usr.id_prnLaser,
      id_prnEtiquetas: usr.id_prnEtiquetas,
      dir_export_DTX: usr.dir_export_DTX,
      password: '',
      password2: ''
    })
  }


  // Método para controlar los cambios en el campo "rol"
  onRolChange(): void {
    this.usuarioForm.get('rol')?.valueChanges.subscribe(rolValue => {
      const id_prnLaser_control = this.usuarioForm.get('id_prnLaser');
      const id_prnEtiquetas_control = this.usuarioForm.get('id_prnEtiquetas');
      const dir_export_DTX_control = this.usuarioForm.get('dir_export_DTX');
      const rolIntranet_control = this.usuarioForm.get('rolIntranet');


      if (rolValue === 'cliente') {
        // Si el rol es "cliente", cambiamos validación requerida
        id_prnLaser_control?.clearValidators();
        id_prnEtiquetas_control?.clearValidators();
        dir_export_DTX_control?.clearValidators();
        rolIntranet_control?.setValidators(Validators.required);
      } else {
        // Si el rol NO es "cliente", cambiamos validación requerida
        id_prnLaser_control?.setValidators(Validators.required);
        id_prnEtiquetas_control?.setValidators(Validators.required);
        dir_export_DTX_control?.setValidators(Validators.required);
        rolIntranet_control?.clearValidators();

        // Actualizamos el estado del campo "rolIntranet"  
        this.usuarioForm.patchValue({ rolIntranet: null });
      }

      // Actualizamos el estado de las validaciones  
      id_prnLaser_control?.updateValueAndValidity();
      id_prnEtiquetas_control?.updateValueAndValidity();
      dir_export_DTX_control?.updateValueAndValidity();
      rolIntranet_control?.updateValueAndValidity();

    });
  }


  getAlmacenes() {
    this.almacenesSrv.getLista()
      .subscribe(respuesta => {
        this.loading = false;
        this.almacenesList = respuesta.data;
      })
  }

  getImpresoras() {
    this.impresorasSrv.listTipo('laser')
      .subscribe(resp => {
        console.log('__getImpresoras', resp)
        this.loading = false;
        this.prnLaserList = resp.data;
      })
  }

  getEtiquetadoras() {
    this.impresorasSrv.listTipo('etiqueta')
      .subscribe(resp => {
        this.loading = false;
        this.prnEtiqList = resp.data;
      })
  }

  getClientes() {
    this.clientesService.getLista()
      .subscribe(resp => {
        this.loading = false;
        this.clientesList = resp.data;
      })
  }



  get f() {
    return this.usuarioForm.controls;
  }


  enviar() {
    // if (this.usuarioForm.get('password')!.value || this.usuarioForm.get('password2')!.value) {
    //   console.log('Hay algo en psw:', this.usuarioForm.get('password')!.value);
    //   this.usuarioForm.setValidators([CustomVal.match('password', 'password2')]);
    // }
    this.enviado = true;

    if (this.usuarioForm.invalid) {
      console.log("ERRORES:", this.usuarioForm.errors);
      return;
    }


    // Todo ok, enviar al BackEnd

    const data = {
      ...this.usuarioForm.value,
      id: this.userId,
    };

    console.log("__enviar()", data)
    console.log("__enviar()this.usuarioForm.value", this.usuarioForm.value)


    // Creamos un nuevo usuario
    if (this.paramId == 'new') {

      this.loading = true;
      this.usuarioSrv.userNew(data)
        .subscribe(resp => {

          console.log('__enviar() resp', resp)

          this.loading = false;
          if (resp.error) {
            this.notificacionesSrv.aviso('error', resp.mensaje);

            for (let controlName in resp.mensaje) {
              const control = this.usuarioForm.get(controlName);
              if (control) control.setErrors({ 'apiError': resp.mensaje[controlName] });
            }

            return
          }

          // Alta Ok
          this.notificacionesSrv.aviso('success', `Usuario creado correctamente`);

          // Hay que volver a algún sitio?
          if (this.volver_a) {
            this.router.navigateByUrl(this.volver_a);
          }
        })

      return
    }


    // Modificamos el usuario
    this.loading = true;
    this.usuarioSrv.userUpdate(data)
      .subscribe(resp => {
        this.loading = false;
        if (resp.error) {
          this.notificacionesSrv.aviso('error', resp.mensaje);
          for (let controlName in resp.mensaje) {
            this.usuarioForm.get(controlName)!.setErrors({ 'apiError': resp.mensaje[controlName] });
          }
          return
        }

        // Modificación Ok
        this.notificacionesSrv.aviso('success', `Usuario modificado correctamente`);


        // Si es el usuario logueado, actualizamos el userdata de la aplicación
        if (this.usuarioSrv.userdata.id?.toString() == this.userId) {
          this.usuarioSrv.userdata.nombre = this.usuarioForm.value.nombre;
          this.usuarioSrv.userdata.apellidos = this.usuarioForm.value.apellidos;
          this.usuarioSrv.userdata.alm_por_defecto = this.usuarioForm.value.alm_por_defecto;
        }

        // Hay que volver a algún sitio?
        if (this.volver_a) {
          this.router.navigateByUrl(this.volver_a);
        }

      })
  }



  // Método para cambiar el estado 'disabled' del control 'rol'
  cambiarEstadoRol(disabled: boolean) {
    const rolControl = this.usuarioForm.get('rol');
    if (rolControl) {
      if (disabled) {
        rolControl.disable();
      } else {
        rolControl.enable();
      }
    }
  }





  // subirFoto() {
  //   this.modalImagenSrv.abrirModal('users', this.usuarioSrv.userdata.id!.toString(), this.usuarioSrv.userdata.foto);
  // }

  // getUserFoto() {
  //   return (this.usuarioSrv.getUserFoto());
  // }


  abrirModal(Usuario: IUsuario) {
    this.modalImagenSrv.abrirModal('users', this.usuario.id?.toString() || '', this.usuario.foto);
  }

} 