<div class="row page-titles">
    <div class="col-md-5 align-self-center">
        <h3 class="text-themecolor"
            style="display: inline;">Distribuir UIC </h3>
        <h5 style="display: inline;font-style: italic;">Campaña: {{totales.campanya?.camp_cod}}</h5>
        <h3 class="text-themecolor">
            <i class="fa-solid fa-dolly"></i> {{uic}}
        </h3>
    </div>
</div>





<div class="animated fadeIn fast">

    <div class="row">

        <div class="col-12">
            <div class="card">

                <div class="card-body">

                    <div class="row">
                        <div class="custom-input">

                            <div class="col-6">

                                <form class="form"
                                      [ngClass]="{'form-horizontal': true}">

                                    <!-- eanInput -->
                                    <div class="form-group"
                                         [ngClass]="{ 'has-danger': eanError }">
                                        <label class="form-control-label">EAN:</label>
                                        <input type="text"
                                               name="eanInput"
                                               [(ngModel)]="eanInput"
                                               class="form-control"
                                               (ngModelChange)="validarEAN()"
                                               #eanInputField />

                                        <div *ngIf="eanError"
                                             class="form-control-feedback">
                                            <div>EAN inexistente en este UIC</div>
                                        </div>
                                    </div>

                                </form>

                            </div>


                            <div class="col-6">
                                <button class="btn btn-primary btn-sm ml-4"
                                        (click)="pdfEtiquetaMulti()"
                                        type="button">
                                    <i class="fa-solid fa-print fa-2xl"></i> Etiq. cajas
                                </button>
                            </div>

                        </div>

                    </div> <!-- row -->

                </div>


            </div> <!-- card -->
        </div> <!-- col-12 -->

    </div> <!-- row -->



    <!-- Array de artículos en el carro -->


    <div class="row">

        <div class="col-lg-12">

            <div class="card mt-3">
                <div class="card-body">
                    <h3 class="text-themecolor">Quedan {{totalArticulos-articulosRepartidos}} artículos en el carro</h3>
                    <div class="table-responsive">
                        <table class="table">
                            <thead>
                                <tr>
                                    <th colspan="2">Artículo</th>
                                    <th class="text-right">Cant</th>
                                </tr>
                            </thead>
                            <tbody>
                                <ng-container *ngFor="let item of eanListAgrupados">
                                    <tr>
                                        <td colspan="3"
                                            class="horizontal-rule">
                                            <hr>
                                        </td>
                                    </tr>
                                    <tr (click)="articClick(item)"
                                        class="pointer">
                                        <td>
                                            <div *ngIf="item.articulo.foto != '_noFoto.png'"
                                                 class="text-center">
                                                <img [src]="fotoDir +'/articulos' + imgResized + item.articulo.foto"
                                                     loading="lazy"
                                                     alt="Foto"
                                                     class="foto"
                                                     title="foto">
                                            </div>
                                        </td>
                                        <td>
                                            <div>
                                                <strong>Sku:</strong> {{ item.articulo.sku }}
                                            </div>
                                            <div>
                                                <strong>EAN:</strong> {{ item.articulo.ean }}
                                            </div>
                                        </td>
                                        <td class="text-right">{{ item.cant_repartir-item.cant_repartida }}</td>
                                    </tr>
                                    <tr (click)="articClick(item)"
                                        class="pointer">
                                        <td class="custom-td"
                                            colspan="3"
                                            [innerHTML]="limitarYAgregarPuntos(item.articulo.descripcion, 35)"
                                            [title]="item.articulo.descripcion"></td>

                                    </tr>
                                </ng-container>

                            </tbody>
                        </table>
                    </div>
                </div>


            </div> <!-- card -->

        </div> <!-- col-12 -->
    </div> <!-- row -->



</div>