import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable, throwError } from 'rxjs';

// servicios
import { NotificacionesService } from '../notificaciones.service'; 
import { UsuarioService } from '../datos/usuario.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(
    private usuarioSrv: UsuarioService,
    private notificacionesSrv: NotificacionesService
  ) { }
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    // Verificar si es una solicitud de inicio de sesión o un refreshToken, dejarlas pasar
    if (request.url.includes('/login')) {
      return next.handle(request);
    }
    if (request.url.includes('/refreshToken')) {
      return next.handle(request);
    }


    // Agregar cabecera de autenticación para todas las demás solicitudes

    console.log("Add cabecera desde AuthInterceptor para:", request.urlWithParams);

    const token = localStorage.getItem('token') || '';
    const headers = request.headers.append('Authorization', 'Bearer ' + token);
    const authRequest = request.clone({ headers });

    //return next.handle(authRequest)

    return next.handle(authRequest).pipe(
      catchError((mensaje: HttpErrorResponse) => {
        console.log('__error en Intereptor mensaje:', mensaje);
        this.notificacionesSrv.aviso('error', mensaje.error);

        // Redirigir a la página de login        
        this.usuarioSrv.logOut();
        return throwError(() => mensaje);
      })
    )

  }

}
