import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { UsuarioService } from './usuario.service';
import { NotificacionesService } from '../notificaciones.service';
import { Router } from '@angular/router';
import { IApiResp } from 'src/app/interfaces/api-resp';
import { catchError, throwError } from 'rxjs';
import { ITransaccion } from 'src/app/interfaces/transacciones';
import { IListaOpciones } from 'src/app/interfaces/lista-opciones';
import { IEntrada } from 'src/app/interfaces/entrada';

@Injectable({
  providedIn: 'root'
})
export class TransaccionesService {

  apiURL: string = environment.apiURL;

  constructor(
    private http: HttpClient,
    private usuarioSrv: UsuarioService,
    private router: Router,
    private notificacionesSrv: NotificacionesService,
  ) { }

  getLista() {
    // Devulve la lista de usuarios
    const path = `${this.apiURL}/transacciones/list`;
    const lista = this.http.get<IApiResp>(path)
    return lista;
  }


  getListaFiltrada(_opciones: IListaOpciones = {}) {
    // Devulve la lista filtrada
    const path = `${this.apiURL}/transacciones/listFiltro`;

    const data = {
      id_almacen: _opciones.id_almacen || null,
      id_cliente: _opciones.id_cliente || null,
      id_articulo: _opciones.id_articulo || null,
      id_ubicacion: _opciones.id_ubicacion || null
    };

    return this.http.post<IApiResp>(path, data)
      .pipe(
        catchError(error => {
          console.log('ERROR:', error);
          this.errorAuth();
          return throwError(() => new Error(error));
        })
      )
  }

  update(transaccion: ITransaccion) {
    const path = `${this.apiURL}/transacciones/update/${transaccion.id}`;

    return this.http.post<IApiResp>(path, transaccion)
      .pipe(
        catchError(error => {
          console.log('ERROR:', error);
          this.errorAuth();
          return throwError(() => new Error(error));
        })
      )
  }

  delete(id: number) {
    const path = `${this.apiURL}/transacciones/delete/${id}`;
    return this.http.get<IApiResp>(path)
      .pipe(
        catchError(error => {
          console.log('ERROR:', error);
          this.errorAuth();
          return throwError(() => new Error(error));
        })
      )
  }



  TransaccionEntrada(datos: any) {
    const path = `${this.apiURL}/transacciones/transaccionEntrada`;

    return this.http.post<IApiResp>(path, datos)
      .pipe(
        catchError(error => {
          console.log('ERROR:', error);
          this.errorAuth();
          return throwError(() => new Error(error));
        })
      )
  }


  TransaccionSalidaPlaya(entrada: IEntrada) {
    const path = `${this.apiURL}/transacciones/transaccionSalidaPlaya`;

    return this.http.post<IApiResp>(path, entrada)
      .pipe(
        catchError(error => {
          console.log('ERROR:', error);
          this.errorAuth();
          return throwError(() => new Error(error));
        })
      )
  }



  TransacDeleteEntrada(datos: any) {
    const path = `${this.apiURL}/transacciones/transacDeleteEntrada`;

    return this.http.post<IApiResp>(path, datos)
      .pipe(
        catchError(error => {
          console.log('ERROR:', error);
          this.errorAuth();
          return throwError(() => new Error(error));
        })
      )
  }

  ///// AUX ////

  errorAuth() {
    this.usuarioSrv.logOut();
    console.log('error', environment.apiErrorAuth);
    // this.notificacionesSrv.aviso('error', environment.apiErrorAuth);
  }


}
