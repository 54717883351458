import { ActivatedRoute, Router } from '@angular/router';
import * as Const from '../../shared/constants';

// Componentes
import { Component, OnInit } from '@angular/core';

//Modelos e Interfaces 
import { IEntrada, IEntradaDetall } from 'src/app/interfaces/entrada';

// Servicios
import { EntradasService } from 'src/app/services/datos/entradas.service';
import { NotificacionesService } from 'src/app/services/notificaciones.service';

// Librerias
import Swal from 'sweetalert2';


@Component({
  selector: 'app-entrada-articulos',
  templateUrl: './entrada-articulos.component.html',
  styleUrls: ['./entrada-articulos.component.scss']
})
export class EntradaArticulosComponent implements OnInit {

  // Usar constantes en el template
  Const = Const
  private id_entrada: number | null = null

  entrada: IEntrada = {}
  paleSelected: number | null = null;
  paleSelectedDetall: IEntradaDetall[] = [];

  public ubicarPaleEntero: boolean = false

  destino: string | null = null;
  ean: string | null = null;
  public registroEncontrado: IEntradaDetall = {}

  public destinoPale: string | null = null;
  public destinoEan: string | null = null;

  public destinoError: boolean = false
  public eanError: boolean = false
  public muestraEan: boolean = false


  loading: boolean = false;

  constructor(
    private entradaSrv: EntradasService,
    public notifciSrv: NotificacionesService,
    private activatedRoute: ActivatedRoute,
    private router: Router

  ) { }

  ngOnInit(): void {
    // Suscribirse a los parámetros de la url
    this.activatedRoute.params.subscribe(params => {
      this.id_entrada = params['id_entrada']
      this.getEntrada()
    })

  }


  // Get entrada con detall
  getEntrada() {
    const opcionesList = { id: this.id_entrada! }
    this.loading = true;

    this.entradaSrv.getLista(opcionesList)
      .subscribe(resp => {
        this.loading = false;
        if (resp.error) {
          this.notifciSrv.aviso('error', resp.mensaje);
          return
        } else {
          this.entrada = resp.data[0];
          console.log('__getEntrada:', resp.data[0]);

          // Filtrar la entrada solo con los detalles swEnStock = '1'
          const filteredDetall = this.entrada.detall!.filter((detalle: any) => detalle.swEnStock == 1);
          this.entrada.detall = filteredDetall;
          console.log('__getEntradaFILTRADA:', this.entrada);

          if (filteredDetall.length === 0) {
            this.notifciSrv.aviso('success', 'Todas las entradas validadas')
            this.router.navigate(['/pda']);
          }
        }
      })

  }

  selectPale(lin: IEntradaDetall) {
    this.resetForm()

    this.ean = null
    this.muestraEan = false
    this.registroEncontrado = {}

    this.paleSelected = lin.pale!
    const mismaUbicacion = this.sonMismaUbicacion(this.paleSelected);

    // detalle del palé seleccionado
    this.paleSelectedDetall = this.entrada.detall!.filter((detalle: IEntradaDetall) => detalle.pale === this.paleSelected);

    console.log('__paleSelectedDetall:', this.paleSelectedDetall);

    if (mismaUbicacion) {
      // todo el palé va a la misma ubicación, ubicarPaleEntero = true
      this.ubicarPaleEntero = true
      this.destino = lin.ubicacion!

      // Excepto si se ha ubicado artículo a artículo
      if (this.paleSelectedDetall[0].swUbiDesdePale == '2') {
        this.ubicarPaleEntero = false
        this.destino = null
        this.muestraEan = true
      }
      return
    } else {
      // Artículos del palé a diferentes ubicaciones
      this.ubicarPaleEntero = false
      this.destino = null
      this.muestraEan = true
      return
    }


  }

  // Validar que el ean existe en paleSelectedDetall
  validarEan() {
    this.resetForm()

    this.registroEncontrado = this.paleSelectedDetall.find(detalle => detalle.artEan === this.ean) || {};

    console.log('__this.ean:', this.ean);
    console.log('__registroEncontrado:', this.registroEncontrado);

    if (!this.registroEncontrado.artEan) {
      this.eanError = true;
      return
    }

    // ean Correcto
    this.muestraEan = false;
    return

  }


  // Validar que el destino del fomulario es igual al destino del EAN en el palé
  validarDestinoEan() {
    console.log('__validarDestinoEan destinoEan', this.destinoEan)
    console.log('__validarDestinoEan ubicacion', this.registroEncontrado.ubicacion)
    this.destinoError = this.destinoEan !== this.registroEncontrado.ubicacion;
    if (this.destinoError) {
      return
    }

    // Destino validado, actualizar swEnStock para el registro de su id_entrada y id_detalle
    const datos = {
      'id_entrada': this.entrada.id,
      'detalle': this.registroEncontrado
    }
    this.loading = true
    this.entradaSrv.confirmarPda(datos).subscribe(resp => {
      this.loading = false
      if (resp.error) {
        this.notifciSrv.aviso('error', resp.mensaje)
        return
      }

      //this.notifciSrv.aviso('success', 'Ubicación de artículo confirmada')
      this.notifciSrv.aviso('success', resp.mensaje)
      this.resetForm()
      this.paleSelected = null
      this.ean = null
      this.registroEncontrado = {}
      //this.muestraEan = false
      this.paleSelectedDetall = [];
      this.ubicarPaleEntero = false

      this.getEntrada()
      return
    })

  }



  // Validar que el destino del fomulario es igual al destino del palé
  validarDestinoPale() {
    this.destinoError = this.destinoPale !== this.destino;
    if (this.destinoError) return

    // Destino validado, actualizar swEnStock para los registros de su id_entrada y pale
    const datos = {
      'id_entrada': this.entrada.id,
      'pale': this.paleSelected
    }
    this.loading = true
    this.entradaSrv.confirmarPda(datos).subscribe(resp => {
      this.loading = false
      if (resp.error) {
        this.notifciSrv.aviso('error', resp.mensaje)
        return
      }
      this.notifciSrv.aviso('success', resp.mensaje)
      this.resetForm()
      this.paleSelected = null
      this.paleSelectedDetall = [];
      this.ubicarPaleEntero = false

      this.getEntrada()
      return
    })
  }




  ////// AUX /////

  // Son todas las ubicaciones del palé iguales?
  sonMismaUbicacion(pale: number): boolean {
    // Filtrar todos los artículos con el mismo pale
    const samePaleItems = this.entrada.detall!.filter(item => item.pale === pale);

    // Verificar si todas las ubicaciones son iguales
    if (samePaleItems.length === 0) {
      return false; // Si no hay artículos con el pale especificado, devolver false
    }

    return samePaleItems.every(item => item.ubicacion === samePaleItems[0].ubicacion);
  }

  resetForm() {
    this.eanError = false;
    this.destinoError = false;
    this.destinoPale = null;
    this.destinoEan = null;
  }

}
