<div class="row page-titles">
    <div class="col-md-5 align-self-center">
        <h3 class="text-themecolor">Resumen de la preparación:</h3>
        <span *ngIf="campanya.campanyaTipo !== '1 a 1'">
            <h3 class="text-themecolor">{{recogida_cod}}</h3>
        </span>
        <span *ngIf="campanya.campanyaTipo === '1 a 1'">
            <h3 class="text-warning d-flex align-items-center">
                <img src="../../../assets/images/icon/1a1.png"
                     height="30px"
                     alt="1 a 1"
                     class="mr-2">
                {{recogida_cod}}
            </h3>
        </span>
        <h6>{{totalUbis | number: '0.0-0':'es'}} Recogidas</h6>
    </div>
</div>





<div class="col-lg-12"
     style="padding: 1px">

    <div class="card"
         *ngFor="let uic of getPreparacionesAgrupadasKeys()">

        <h5 class="text-success"> <i class="fa-solid fa-dolly"></i> {{ uic }}</h5>
        <table class="table table-sm">
            <thead>
                <tr>
                    <th>EAN</th>
                    <th>Sku</th>
                    <th class="text-right">Cant.</th>
                    <th class="text-right">Recg.</th>
                    <th>Ubicación</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let recPrep of preparacionesAgrupadas[uic]"
                    [ngClass]="{'bg-warning': recPrep.estado=='8'}">
                    <td>{{ recPrep.ean }}</td>
                    <td>{{ recPrep.sku }}</td>
                    <td class="text-right">
                        <ng-container *ngIf="recPrep.cant_inicial == 0">
                            {{ recPrep.cant_reserva | number: '0.0-0':'es' }}
                        </ng-container>
                        <ng-container *ngIf="recPrep.cant_inicial != 0">
                            {{ recPrep.cant_inicial | number: '0.0-0':'es' }}
                        </ng-container>
                    </td>
                    <td class="text-right">{{ recPrep.cant_recogida | number: '0.0-0':'es' }}</td>
                    <td class="monospace">{{ recPrep.ubicacion! | mask: '00.00.00.00.00.00' }}</td>
                </tr>
            </tbody>
        </table>

    </div>

</div>


<div class="float-left">
    <button *ngIf="!loading"
            type="button"
            (click)="volverClick()"
            class="btn waves-effect waves-light btn-rounded btn-success btn-sm">
        Volver
    </button>
</div>

<!--Enviar Pedido (pedido 1 a 1 y NO servido)-->
<div class="float-right"
     *ngIf="campanya.campanyaTipo === '1 a 1'">
    <!-- *ngIf="campanyaInfo.campanyaTipo === '1 a 1' && pedidoCero.estado === '1'" -->

    <button *ngIf="!loading"
            type="button"
            (click)="enviarPedidoClick()"
            class="btn waves-effect waves-light btn-rounded btn-warning btn-sm">
        <i class="fa-solid fa-truck-arrow-right"></i> Enviar Pedido
    </button>
</div>