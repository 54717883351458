import * as Constantes from '../../../shared/constants';

// módulos
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

// modelos / interfaces
import { IEntrada, IEntradaDetall } from 'src/app/interfaces/entrada';
import { IListaOpciones } from 'src/app/interfaces/lista-opciones';

// servicios 
import { EntradasService } from 'src/app/services/datos/entradas.service';
import { UbicacionesService } from 'src/app/services/datos/ubicaciones.service';
import { NotificacionesService } from 'src/app/services/notificaciones.service';
import { TransaccionesService } from 'src/app/services/datos/transacciones.service';

// librerías / envir.
import CustomVal from 'src/app/providers/CustomValidators';
import { environment } from 'src/environments/environment';
import FuncLib from 'src/app/providers/funcionesLib';
import Swal from 'sweetalert2';


@Component({
  selector: 'app-entrada-ubica-articulo',
  templateUrl: './entrada-ubica-articulo.component.html',
  styleUrls: ['./entrada-ubica-articulo.component.scss']
})
export class EntradaUbicaArticuloComponent implements OnInit {

  // Usar constantes en el template
  Constantes = Constantes

  public produccion: boolean = environment.production;

  private id_entrada: number | undefined;
  private id_detall: number | undefined;

  public fotoDir: string = environment.fotoDir;

  opcionesList: IListaOpciones = {};
  public entrada: IEntrada = {};
  public detall: IEntradaDetall = {};

  public ubiForm: FormGroup = new FormGroup({});

  public enviado: boolean = false;
  public loading: boolean = false;

  private funcLib: FuncLib = new FuncLib();
  public codUbiDecript: string = '';


  constructor(
    private entradaSrv: EntradasService,
    private transacSrv: TransaccionesService,
    private ubicacionesSrv: UbicacionesService,
    private fb: FormBuilder,
    private notificacionesSrv: NotificacionesService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.getParametrosUrl();

    // Definir el form de ubicación
    this.ubiForm = this.fb.group({
      ubicacion: ['', [
        Validators.required,
        Validators.minLength(12),
        Validators.maxLength(12),
        CustomVal.empiezaPor('ubicacion', '0')
      ]],
      cantidad: ['']
    })
  }

  getParametrosUrl() {
    // Suscribirse a los parámetros de la url 
    this.activatedRoute.params.subscribe(({ id, id_detall }) => {
      this.id_entrada = id;
      this.id_detall = id_detall;

      this.getEntrada();
    })
  }


  getEntrada() {
    // Cargar los valores de la entrada para ese artículo
    this.opcionesList.id = this.id_entrada;
    this.loading = true;

    this.entradaSrv.getLista(this.opcionesList)
      .subscribe(resp => {
        this.loading = false;
        const datosFiltrados = resp.data[0].detall
          .filter((item: any) => item.id == this.id_detall);

        this.entrada = {
          ...resp.data[0],
          detall: datosFiltrados, // actualiza "detall" con los elementos filtrados solo pare el artículo
        };

        console.log('__getEntrada()', this.entrada);

        this.detall = this.entrada.detall![0];
        this.ubiForm.patchValue({ cantidad: this.detall.cantidad });

        // Actualizar los validadores de ubiForm

        this.ubiForm.get('ubicacion')?.setValidators([
          Validators.required,
          Validators.minLength(12),
          Validators.maxLength(12),
          CustomVal.empiezaPor('ubicacion', this.entrada.almCodigo!) // Asignar el nuevo valor de comparación
        ]);
        this.ubiForm.get('ubicacion')?.updateValueAndValidity();

        this.ubiForm.get('cantidad')?.setValidators([
          Validators.required,
          Validators.max(this.detall.cantidad!),
          CustomVal.esEntero
        ]);
        this.ubiForm.get('cantidad')?.updateValueAndValidity();

      })

  }


  enviarUbi() {
    this.enviado = true;

    if (this.ubiForm.invalid) {
      console.log("ERRORES:", this.ubiForm);
      return;
    }

    const datos = {
      ubicacion: this.ubiForm.get('ubicacion')?.value,
      cantidad: this.ubiForm.get('cantidad')?.value,
      entrada: this.entrada,
      swUbiDesdePale: '2'
    };


    this.loading = true;
    this.ubicacionesSrv.ubicaArticulo(datos)
      .subscribe(resp => {
        this.loading = false;
        console.log('enviarUbi', resp);

        if (resp.error) {
          this.notificacionesSrv.aviso('error', resp.mensaje);
          console.log(resp.mensaje);
          return
        } else {
          this.TransaccionSalidaPlaya(datos);
          // Transacción entrada en Stock
          this.notificacionesSrv.aviso('success', `${resp.mensaje}`);
          this.router.navigate(['./panel/entradas_lista']);
        }

      });

  }


  TransaccionSalidaPlaya(datos: any) {
    this.loading = true;
    this.transacSrv.TransaccionSalidaPlaya(datos).subscribe(resp => {
      this.loading = false;
    })
  }


  public onRowUbiClick(rowEvent: any) {
    // Validar y cargar ubicación seleccionada
    const row = rowEvent.selected[0]; // Obtiene el objeto de la línea seleccionada

    console.log('__onRowUbiClick()', row)

    // comprobar lote
    if (row.lote && (row.lote !== this.entrada.detall![0].lote)) {
      // this.notificacionesSrv.aviso('danger', `En la ubicación selecciona existe este artíclo pero con lote <b>${row.lote}</b>`);

      Swal.fire({
        title: 'No puedes mezclar lotes',
        icon: 'error',
        html: `En la ubicación seleccionada existe este artíclo pero con lote <b>${row.lote}</b>`,
        showConfirmButton: true,
        confirmButtonText: "Cerrar",
      });
      return
    }

    // cargar ubicación seleccionada
    this.ubiForm.patchValue({ ubicacion: row.codigo });
    this.cambiaUbi();

  }


  public cambiaUbi() {
    const ubicacion = this.ubiForm.get('ubicacion')?.value;
    this.codUbiDecript = this.funcLib.CodUbiDecript(ubicacion);
  }


  get f() {
    return this.ubiForm.controls;
  }


} 
